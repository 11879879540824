import { Button } from 'antd';
import { ButtonProps } from 'antd/lib';
import { CSSProperties, FC, useState } from 'react';
import Lottie from 'react-lottie-player';

type LottieButtonProps = ButtonProps & {
  animationData: object;
  lottieStyle?: CSSProperties;
};

export const LottieButton: FC<LottieButtonProps> = ({ lottieStyle, animationData, ...rest }) => {
  const [hover, setHover] = useState<boolean>(false);

  return (
    <Button
      onMouseEnter={(): void => setHover(true)}
      onMouseLeave={(): void => setHover(false)}
      icon={<Lottie animationData={animationData} loop={false} goTo={!hover ? 0 : undefined} play={hover} style={lottieStyle} />}
      type="default"
      {...rest}
    />
  );
};
