import { Input, InputProps } from 'antd';
import { Field, FieldProps } from 'formik';
import { FC } from 'react';
import { FormLabel } from './FormLabel';

interface Props extends InputProps {
  fieldName: string;
  placeholder?: string;
  type?: string;
  label: string;
}

export const FieldInput: FC<Props> = ({ fieldName, placeholder, type, label, ...rest }) => {
  // const { handleSubmit } = useFormikContext();

  return (
    <Field name={fieldName} placeholder={placeholder}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { setFieldTouched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }: FieldProps): JSX.Element => {
        return (
          <div>
            <FormLabel label={label} />
            <Input
              // onKeyDown={handleEnter}
              onFocus={(): void => {
                setFieldTouched(fieldName, false);
              }}
              style={{ borderColor: meta.error && meta.touched ? '#dc3545' : undefined }}
              type={type}
              {...field}
              {...rest}
            />
            <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
          </div>
        );
      }}
    </Field>
  );
};
