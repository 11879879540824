import { Row, Select, Spin } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { Field, FieldProps } from 'formik';
import { useAuthentication } from 'hooks/useAuthentication';
import { rest } from 'lodash';
import { AcuityContext } from 'models/Application';
import { FC, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useGetReleasedProductsQuery } from 'redux/services/donaldGrump/donaldGrumpApi';
import { incrementOffset, setProductNumber } from 'redux/services/donaldGrump/releasedProductsParams';
import { useGetProductCatalogItemsQuery } from 'redux/services/julia/juliaApi';
import { useAppSelector } from 'redux/store';
import { useDebouncedCallback } from 'use-debounce';
import { FormLabel } from './FormLabel';

export const ProductSelect: FC = () => {
  const { donaldGrumpReleasedProductsParams } = useAppSelector((state) => state);
  const { acuityContext } = useAppSelector((state) => state.app);
  const dispatch = useDispatch();
  const {
    selectedCustomer: {
      business: { id }
    }
  } = acuityContext as AcuityContext;
  const { isAdmin } = useAuthentication();
  const { data, isLoading } = useGetProductCatalogItemsQuery({ customerId: id as string, params: {} }, { skip: !id || isAdmin });

  const { data: releasedProductData, isLoading: isReleasedLoading, isFetching } = useGetReleasedProductsQuery(donaldGrumpReleasedProductsParams, { skip: !isAdmin });
  const options = data?.data?.map((product) => ({ label: product.productNumber, value: product.productNumber })) ?? [];

  const productsOptions = (): { label: string; value: string }[] | undefined => {
    if (releasedProductData?.data) {
      return releasedProductData.data.map((product) => ({ label: product.productNumber, value: product.productNumber, key: product.id }));
    }

    return options;
  };

  const dropdownRef = useRef<HTMLDivElement>(null);
  const handleChange = useDebouncedCallback((value: string) => {
    dispatch(setProductNumber(value || undefined));
  }, 500);

  const handleSearch = (val: string): void => {
    if (!val) {
      dispatch(setProductNumber(undefined));

      return;
    }
    handleChange(val);
  };

  const handleFilterOption = (input: string, option: DefaultOptionType | undefined): boolean => {
    if (!!option && typeof option.label === 'string')
      return (
        !!option &&
        !!option.value &&
        typeof option.value === 'string' &&
        typeof option.label === 'string' &&
        (option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0)
      );

    return !!option && !!option.value && typeof option.value === 'string' && option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const fetchMore = (): void => {
    dispatch(incrementOffset());
  };

  const handleScroll = (e: React.UIEvent<HTMLElement>): void => {
    const { scrollTop, offsetHeight, scrollHeight } = e.currentTarget;

    setTimeout(() => {
      if (scrollTop + offsetHeight >= scrollHeight - 1.5) {
        fetchMore();
      }
    }, 50);
  };

  return (
    <Spin spinning={isLoading || isReleasedLoading || isFetching}>
      <Field name={'productNumber'}>
        {({
          field, // { name, value, onChange, onBlur }
          form: { setFieldTouched, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
          meta
        }: FieldProps): JSX.Element => {
          const handleChange = (val: string): void => {
            const findDescription = isAdmin
              ? releasedProductData?.data?.find((product) => product.productNumber === val)?.productDescription
              : data?.data?.find((product) => product.productNumber === val)?.productDescription;

            setFieldValue('description', findDescription);
            setFieldValue('productNumber', val);
          };

          return (
            <Row style={{ width: '100%' }}>
              <FormLabel label={'Product Number'} />
              <Select
                {...field}
                dropdownRender={(menu): JSX.Element => <div ref={dropdownRef}>{menu}</div>}
                showSearch
                allowClear
                onClear={(): void => {
                  setFieldTouched('productNumber', true);
                }}
                filterOption={!isAdmin && handleFilterOption}
                onPopupScroll={isAdmin ? handleScroll : undefined}
                options={productsOptions()}
                onSearch={isAdmin ? handleSearch : undefined}
                status={meta.error && meta.touched ? 'error' : undefined}
                onChange={handleChange}
                onFocus={(): void => {
                  setFieldTouched('productNumber', false);
                }}
                onBlur={(): void => {
                  setFieldTouched('productNumber', true);
                }}
                style={{ width: '100%' }}
                {...rest}
              />

              <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
            </Row>
          );
        }}
      </Field>
    </Spin>
  );
};
