import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { ProductCatalogItem } from 'models/ProductCatalog';
import { ReduxState } from 'redux/store';
import { ApiMultipleResponse } from '../hectorTheSpider/hectorTheSpiderApi';

/* ******************** Base Query ******************** */
const baseUrl = process.env.REACT_APP_JULIA_BASE_URL;
const functionsKey = process.env.REACT_APP_API_HOST_KEY_JULIA;

export const juliaBaseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as ReduxState).app.accessToken;

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
      headers.set('x-functions-key', functionsKey);
      headers.set('Content-Type', 'application/json');
    }

    return headers;
  }
});

export const juliaApi = createApi({
  reducerPath: 'juliaApi',
  baseQuery: juliaBaseQuery,
  tagTypes: ['ProductCatalogItem', 'ProductCatalogItems', 'DeletedProductCatalogItem'],
  endpoints: (builder) => ({
    /* ****************** Business Endpoints ****************** */

    getProductCatalogItems: builder.query<ApiMultipleResponse<ProductCatalogItem>, { customerId: string; params: { productNumberContains?: string } }>({
      query: ({ customerId, params }) => ({
        url: `customers/${customerId}/productCatalogItems`,
        params
      }),
      providesTags: ['ProductCatalogItems']
    }),

    getVersion: builder.query<string, void>({
      query: () => ({
        url: '/diagnostics/version',
        responseHandler: (response): Promise<string> => response.text()
      })
    }),
    getApiName: builder.query<string, void>({
      query: () => ({
        url: '/diagnostics/apiName',
        responseHandler: (response): Promise<string> => response.text()
      })
    })
  })
});

export const { useGetProductCatalogItemsQuery, useGetVersionQuery, useGetApiNameQuery } = juliaApi;
