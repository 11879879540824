import { Col, Form, Row } from 'antd';
import { FieldInput } from 'components/atoms/FieldInput';

export const UserForm = (): JSX.Element => {
  return (
    <Form layout="vertical">
      <Row gutter={[32, 16]}>
        <Col span={24}>
          <FieldInput fieldName="givenName" label="First Name" />
        </Col>
        <Col span={24}>
          <FieldInput fieldName="familyName" label="Last Name" />
        </Col>
        <Col span={24}>
          <FieldInput fieldName="phoneNumber" label="Phone Number" />
        </Col>
        <Col span={24}>
          <FieldInput fieldName="email" label="Email" />
        </Col>
      </Row>
    </Form>
  );
};
