import { useAuth0 } from '@auth0/auth0-react';
import Cookies from 'js-cookie';
import { AcuityContext } from 'models/Application';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAccessToken, setAcuityContext, setUser } from 'redux/slices/appSlice';
import { useAppSelector } from 'redux/store';

export const useAuthentication = (): { isLoading: boolean; cookieNotFound: boolean; isAdmin: boolean } => {
  const { accessToken } = useAppSelector((state) => state.app);
  const dispatch = useDispatch();
  const { isAuthenticated, loginWithRedirect, isLoading, user, getAccessTokenSilently } = useAuth0();
  const [cookieNotFound, setCookieNotFound] = useState(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  // Check if User is logged in, if not, redirect them to login screen
  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({ appState: { targetUrl: '/' } });
    }
    if (isAuthenticated && accessToken === null) {
      getAccessTokenSilently().then((accessToken) => dispatch(setAccessToken(accessToken)));
    }
  }, [accessToken, dispatch, getAccessTokenSilently, isAuthenticated, isLoading, loginWithRedirect]);

  useEffect(() => {
    if (user) {
      dispatch(setUser(user));
    }
  }, [dispatch, user]);

  // Fetch cookie and set in store
  useEffect(() => {
    const cookie = Cookies.get(`${process.env.REACT_APP_COOKIE_PREFIX}_acuity_context`);

    const cookieJSON: AcuityContext = cookie ? JSON.parse(cookie) : undefined;

    if (cookieJSON?.selectedCustomer) {
      dispatch(setAcuityContext(cookieJSON));
    } else {
      setCookieNotFound(true);
    }
  }, [cookieNotFound, dispatch, accessToken, user]);

  useEffect(() => {
    if (accessToken) {
      const payload = JSON.parse(window.atob(accessToken.split('.')[1]));

      const permissionsPayload = payload.permissions;

      const isAdminPermissions = permissionsPayload.some((perm: string) => {
        if (perm.toLowerCase().includes('superadmin')) {
          return true;
        }
        if (perm.toLowerCase().includes('internaladmin:poimports')) {
          return true;
        }

        return false;
      });

      setIsAdmin(isAdminPermissions);

      if (cookieNotFound && !isAdminPermissions) {
        setTimeout(() => {
          window.location.href = process.env.REACT_APP_ERNIE_REDIRECT_URL || '';
        }, 5000);
      }
    }
  }, [cookieNotFound, accessToken]);

  return { isLoading, cookieNotFound, isAdmin };
};
