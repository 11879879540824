import { Col, Modal, Row, Space, Spin, Typography, notification } from 'antd';
import { PostButton } from 'components/atoms/PostButton';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { GeneralInfoCard } from 'components/molecules/GeneralInfoCard';
import { ImportTableCard } from 'components/organisms/ImportTableCard';
import { FormikProvider, useFormik } from 'formik';
import { useAuthentication } from 'hooks/useAuthentication';
import { pOImportPayloadSchema } from 'models/POImport';
import { LineItemErrorObject, usePostLineItemsMutation } from 'redux/services/hectorTheSpider/hectorTheSpiderApi';
import { useAppSelector } from 'redux/store';
import { utils, writeFile } from 'xlsx';

export type LineItem = {
  divisionId?: string;
  lineNumber: number;
  productNumber: string;
  ownerId: string;
  conditionId: string;
  dispositionId: string;
  description?: string;
  quantity: number;
  unitCost: number;
};
export type POImportPayload = {
  customerReferenceNumber: string;
  divisionId?: string;
  vendorId: string;
  requestedDeliveryDate: string;
  warehouseId: string;
  vendorOrderNumber?: string;
  lineItems: LineItem[];
};

export const Home = (): JSX.Element => {
  const [postLineItems] = usePostLineItemsMutation();
  const { isAdmin } = useAuthentication();
  const { importLoadingMessage, isImporting } = useAppSelector((state) => state.import);

  const handleErrorModal = (importErrors: LineItemErrorObject[]): void => {
    Modal.confirm({
      title: 'Import Errors',
      bodyStyle: { maxHeight: 800 },
      cancelText: 'Export',
      onCancel: () => {
        const formattedErrors = importErrors.map(({ productNumber, ownerId, conditionId, dispositionId, quantity, unitCost, errorMessage }) => {
          return {
            ['Product Number']: productNumber,
            ['Owner']: ownerId,
            ['Condition']: conditionId,
            ['Disposition']: dispositionId,
            ['Quantity']: quantity,
            ['Unit Cost']: unitCost,
            ['Error Message']: errorMessage
          };
        });

        const worksheeet = utils.json_to_sheet(formattedErrors);
        const workbook = utils.book_new();

        utils.book_append_sheet(workbook, worksheeet, 'assets');
        writeFile(workbook, 'import-errors.xlsx');
      },
      width: 1000,
      icon: <></>,
      content: (
        <div style={{ border: '1px solid rgba(0,0,0,.1)', borderRadius: 10, padding: 10, marginTop: 20, overflow: 'scroll', height: 600 }}>
          <Row style={{ marginTop: 20, marginLeft: 5, marginBottom: 5 }}>
            <Col span={5}>
              <Typography.Text style={{ fontSize: 15 }}>Item Id</Typography.Text>
            </Col>
            <Col span={19}>
              <Typography.Text style={{ fontSize: 15 }}>Message</Typography.Text>
            </Col>
          </Row>
          {importErrors.map((error, i) => (
            <Row gutter={[10, 10]} key={error.errorMessage + i} style={{ padding: 10, background: 'rgba(0,0,0,.1)', borderRadius: 10, marginBottom: 10 }}>
              <Col span={5}>{error.productNumber}</Col>
              <Col span={19}>{error.errorMessage}</Col>
            </Row>
          ))}
        </div>
      )
    });
  };

  const formik = useFormik<POImportPayload>({
    enableReinitialize: true,
    validationSchema: pOImportPayloadSchema,
    initialValues: {
      customerReferenceNumber: '',
      vendorId: '',
      requestedDeliveryDate: '',
      warehouseId: '',
      vendorOrderNumber: '',
      lineItems: []
    },
    onSubmit: async (values) => {
      const resp = await postLineItems({ payload: values, isAdmin }).unwrap();

      if (resp.data === 'Error') {
        notification.error({
          message: 'Error',
          description: resp.errorMessage,
          className: 'custom-class',
          style: {
            width: 600
          },
          duration: 10
        });

        return;
      }
      notification.success({
        message: 'Success',
        description: resp?.data ?? 'Successfully posted line items',
        className: 'custom-class',
        style: {
          width: 600
        },
        duration: 10
      });
      formik.resetForm();

      !!resp.lineErrors.length && handleErrorModal(resp.lineErrors);
    }
  });

  return (
    <FormikProvider value={formik}>
      <Spin style={{ width: '100%' }} spinning={isImporting} indicator={<LoaderWithMessage loadingMessage={importLoadingMessage} />}>
        <Space style={{ width: '100%' }} direction="vertical">
          <Row justify={'space-between'}>
            <Typography.Title style={{ margin: 0, padding: 0 }} level={4}>
              Create Purchase Order
            </Typography.Title>
            <Space>
              <PostButton />
            </Space>
          </Row>
          <GeneralInfoCard />
          <ImportTableCard />
        </Space>
      </Spin>
    </FormikProvider>
  );
};
