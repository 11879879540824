import { DatePicker } from 'antd/lib';

import { Field, FieldProps, FormikErrors } from 'formik';
import { FC } from 'react';
import { FormLabel } from './FormLabel';

interface Props {
  fieldName: string;
  placeholder?: string;
  label: string;
  disabled?: boolean;
}

export const FieldInputDatePicker: FC<Props> = ({ fieldName, placeholder, label, disabled }) => {
  return (
    <Field name={fieldName} placeholder={placeholder}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { setFieldTouched, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }: FieldProps): JSX.Element => {
        return (
          <div>
            <FormLabel label={label} />
            <DatePicker
              {...field}
              value={field.value}
              format={['MM-DD-YYYY']}
              onChange={(val): void => {
                // const date = dayjs(val).utc().format('YYYY-MM-DD HH:mm:ss[Z]');

                setFieldValue(fieldName, val);
              }}
              onFocus={(): Promise<void | FormikErrors<any>> => setFieldTouched(fieldName, false)}
              style={{ borderColor: meta.error && meta.touched ? '#dc3545' : undefined, width: '100%' }}
              disabled={disabled}
            />
            <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
          </div>
        );
      }}
    </Field>
  );
};
