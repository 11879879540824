import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  importLoadingMessage: string;
  isImporting: boolean;
  isValidating: boolean;
}

const initialState: InitialState = {
  importLoadingMessage: '',
  isImporting: false,
  isValidating: false
};

export const importSlice = createSlice({
  name: 'import',
  initialState,
  reducers: {
    setIsImporting: (state, { payload }: PayloadAction<InitialState['isImporting']>) => {
      state.isImporting = payload;
    },
    setIsValidating: (state, { payload }: PayloadAction<InitialState['isValidating']>) => {
      state.isValidating = payload;
    },
    setImportLoadingMessage: (state, { payload }: PayloadAction<InitialState['importLoadingMessage']>) => {
      state.importLoadingMessage = payload;
    }
  }
});

export const { setImportLoadingMessage, setIsImporting, setIsValidating } = importSlice.actions;
