import { Card, Modal, Typography, message } from 'antd';
import { LineItemForm } from 'components/molecules/LineItemForm';
import { FormikProvider, useField, useFormik } from 'formik';
import { importPayloadSchema } from 'models/POImport';
import { LineItem } from 'pages/Home';
import { FC, Fragment, useState } from 'react';
import editLottie from '../../common/assets/edit-lottie.json';
import { itemKey } from './AddLineModal';
import { LottieButton } from './LottieButton';

type Props = {
  record: LineItem;
  idx: number;
};

export const EditRecordModal: FC<Props> = ({ record, idx }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [{ value }, , { setValue }] = useField<LineItem>(`lineItems[${idx}]`);
  const [{ value: lineItems }] = useField<LineItem[]>(`lineItems`);

  const filteredItems = lineItems.filter((item) => itemKey(item) !== itemKey(record));

  const formik = useFormik<LineItem>({
    enableReinitialize: true,
    validationSchema: importPayloadSchema,
    initialValues: {
      lineNumber: record.lineNumber ?? 0,
      productNumber: record.productNumber ?? '',
      ownerId: record.ownerId ?? '',
      conditionId: record.conditionId ?? '',
      dispositionId: record.dispositionId ?? '',
      description: record.description ?? '',
      quantity: record.quantity ?? 1,
      unitCost: record.unitCost ?? 0.0
    },
    onSubmit: async (values) => {
      if (filteredItems.some((item) => itemKey(item) === itemKey(values))) {
        return message.error('Line item already exists');
      }

      setValue({ ...value, ...values });
      setIsOpen(false);
    }
  });

  return (
    <Fragment>
      <LottieButton
        style={{ borderRadius: '50%', background: '#FFEFCC', borderColor: '#F8AD48' }}
        animationData={editLottie}
        lottieStyle={{ width: 20, height: 20, display: 'inline-block', transform: 'scale(1.0)' }}
        onClick={(): void => setIsOpen(true)}
      />
      <Modal
        open={isOpen}
        closable={false}
        onOk={formik.submitForm}
        onCancel={(): void => {
          setIsOpen(false);
          formik.resetForm();
        }}>
        <FormikProvider value={formik}>
          <Card
            headStyle={{ backgroundColor: 'rgb(255, 234, 193)' }}
            title={
              <Typography.Title level={4} style={{ fontWeight: 'normal', padding: 0, margin: 0 }}>
                Edit Substitution
              </Typography.Title>
            }
            bodyStyle={{ minHeight: '100%' }}
            style={{ height: '100%', minHeight: '100%', width: '100%' }}>
            <LineItemForm />
          </Card>
        </FormikProvider>
      </Modal>
    </Fragment>
  );
};
