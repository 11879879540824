import { Card, Col, Row, Spin, Typography } from 'antd';
import { FieldInput } from 'components/atoms/FieldInput';
import { FieldInputDatePicker } from 'components/atoms/FieldInputDatePicker';
import { FieldInputSelect } from 'components/atoms/FieldInputSelect';
import { useAuthentication } from 'hooks/useAuthentication';
import { useGetDivisionQuery } from 'redux/services/abbyCadabby/abbyCadabbyApi';
import { useGetWarehousesQuery } from 'redux/services/kingstonLivingston/kingstonLivingstonApi';
import { useGetVendorsQuery } from 'redux/services/simonSoundman/simonSoundmanApi';
import { useAppSelector } from 'redux/store';

export const GeneralInfoCard = (): JSX.Element => {
  const { acuityContext } = useAppSelector((state) => state.app);
  const { isAdmin } = useAuthentication();

  console.log(isAdmin);
  const { data: customerData, isLoading: isCustomerDataLoading } = useGetDivisionQuery(acuityContext?.selectedCustomer.id as string, { skip: !acuityContext?.selectedCustomer.id || isAdmin });
  const { data: vendorData, isLoading: isVendorLoading } = useGetVendorsQuery(
    { overrideSkipTake: true, dataAreaId: acuityContext?.selectedCustomer.business.dataAreaId as string },
    { skip: !acuityContext?.selectedCustomer.business.dataAreaId || !isAdmin }
  );

  const dataAreaId = acuityContext?.selectedCustomer.business.dataAreaId;
  const { data: warehouseData, isLoading: isWarehousesLoading } = useGetWarehousesQuery({ overrideSkipTake: true, dataAreaId: dataAreaId as string }, { skip: !dataAreaId });

  const vendorOptions = customerData?.assignedVendors?.map((vendor) => ({ label: vendor, value: vendor }));
  const vendors = (): { label: string; value: string }[] | undefined => {
    if (vendorData?.data) {
      return vendorData.data.map((vendor) => ({ label: vendor.vendorAccountNumber, value: vendor.vendorAccountNumber }));
    }

    return vendorOptions;
  };
  const warehouseOptions = warehouseData?.data.map((vendor) => ({ label: vendor.warehouseId, value: vendor.warehouseId }));

  return (
    <Card
      headStyle={{ backgroundColor: 'rgb(255, 234, 193)' }}
      title={
        <Typography.Title level={4} style={{ fontWeight: 'normal', padding: 0, margin: 0 }}>
          General Information
        </Typography.Title>
      }
      bodyStyle={{ minHeight: '100%' }}
      style={{ height: '100%', minHeight: '100%', width: '100%' }}>
      <Row gutter={[50, 10]}>
        <Col span={12}>
          <FieldInput label="Customer Purchase Order" fieldName="customerReferenceNumber" />
        </Col>
        <Col span={12}>
          <Spin spinning={isCustomerDataLoading || isVendorLoading}>
            <FieldInputSelect options={vendors()} label="Vendor Account" fieldName="vendorId" />
          </Spin>
        </Col>
        <Col span={12}>
          <FieldInput label="Vendor Order Number" fieldName="vendorOrderNumber" />
        </Col>
        <Col span={12}>
          <FieldInputDatePicker label="Requested Delivery Date" fieldName="requestedDeliveryDate" />
        </Col>
        <Col span={12}>
          <Spin spinning={isWarehousesLoading}>
            <FieldInputSelect options={warehouseOptions} label="Warehouse" fieldName="warehouseId" />
          </Spin>
        </Col>
      </Row>
    </Card>
  );
};
