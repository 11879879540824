import * as Yup from 'yup';

export const importPayloadSchema = Yup.object().shape({
  productNumber: Yup.string().required('Product Number is required'),
  ownerId: Yup.string().required('Owner is required'),
  conditionId: Yup.string().required('Condition is required'),
  dispositionId: Yup.string().required('Disposition is required'),
  description: Yup.string().notRequired(),
  quantity: Yup.number().required('Quantity is required').positive('Quantity must be a positive number'),
  unitCost: Yup.number().required('Unit Cost is required')
});

export const pOImportPayloadSchema = Yup.object().shape({
  customerReferenceNumber: Yup.string().required('Customer Reference Number is required'),
  vendorId: Yup.string().required('Vendor ID is required'),
  requestedDeliveryDate: Yup.string().required('Requested Delivery Date is required'),
  warehouseId: Yup.string().required('Warehouse ID is required'),
  vendorOrderNumber: Yup.string().max(30, 'Vendor Order Number cannot exceed 30 characters')
});
