import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Business } from 'models/Business';

export interface InitialState {
  customerSearch?: string;
  cachedBusinessList?: Business[];
}

export const initialState: InitialState = {
  customerSearch: undefined,
  cachedBusinessList: undefined
};

export const businessListSlice = createSlice({
  name: 'businessList',
  initialState,
  reducers: {
    setCustomerSearch: (state, { payload }: PayloadAction<InitialState['customerSearch']>) => {
      state.customerSearch = payload;
    },
    setCachedBusinessList: (state, { payload }: PayloadAction<InitialState['cachedBusinessList']>) => {
      state.cachedBusinessList = payload;
    }
  }
});

export const { setCustomerSearch, setCachedBusinessList } = businessListSlice.actions;
