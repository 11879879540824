import { App } from 'App';
import 'common/styles/antd.customize.less';
import 'common/styles/global.less';
import { Auth0ProviderWithHistory } from 'components/common/Auth0ProviderWithHistory';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from 'redux/store';
import * as serviceWorker from 'serviceWorker';

const container = document.getElementById('root') as HTMLElement;

const root = createRoot(container);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <App />
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
