import { Space } from 'antd';
import { CurrencyInput } from 'components/atoms/CurrencyInput';
import { FieldInputNumber } from 'components/atoms/FieldInputNumber';
import { FieldInputSelect } from 'components/atoms/FieldInputSelect';
import { FieldInputTextArea } from 'components/atoms/FieldInputTextArea';
import { OwnersSelect } from 'components/atoms/OwnersSelect';
import { ProductSelect } from 'components/atoms/ProductSelect';
import { FC } from 'react';

export const LineItemForm: FC = () => {
  const conditionList = ['NEW', 'CERTRECOND', 'USED', 'REFURB'];
  const dispositionList = ['DEPLOY', 'SPARE'];

  return (
    <Space size={15} direction="vertical" style={{ width: '100%' }}>
      <ProductSelect />
      <OwnersSelect />
      <FieldInputSelect fieldName="conditionId" label="Condition" options={conditionList.map((cond) => ({ label: cond, value: cond }))} />
      <FieldInputSelect fieldName="dispositionId" label="Disposition" options={dispositionList.map((disp) => ({ label: disp, value: disp }))} />
      <FieldInputTextArea fieldName="description" label="Description" />
      <FieldInputNumber fieldName="quantity" label="Quantity" />
      <CurrencyInput fieldName="unitCost" label="Unit Cost" />
    </Space>
  );
};
