import { Space, Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import { EditRecordModal } from 'components/atoms/EditRecordModal';
import { LottieButton } from 'components/atoms/LottieButton';
import { useFormikContext } from 'formik';
import { LineItem } from 'pages/Home';
import React from 'react';
import deleteLottie from '../../common/assets/delete-lottie.json';

export const ImportTable: React.FC = () => {
  const {
    setFieldValue,
    values: { lineItems }
  } = useFormikContext<{ lineItems: LineItem[] }>();

  const handleDelete = (product: LineItem): void => {
    setFieldValue(
      'lineItems',
      lineItems.filter((item) => item !== product).map((item, idx) => ({ ...item, lineNumber: idx + 1 }))
    );
  };

  const columns: ColumnType<LineItem>[] = [
    {
      title: 'Line Number',
      dataIndex: 'lineNumber',
      key: 'lineNumber'
    },
    {
      title: 'Product Number',
      dataIndex: 'productNumber',
      key: 'productNumber'
    },
    {
      title: 'Owner',
      dataIndex: 'ownerId',
      key: 'ownerId'
    },
    {
      title: 'Condition',
      dataIndex: 'conditionId',
      key: 'conditionId'
    },
    {
      title: 'Disposition',
      dataIndex: 'dispositionId',
      key: 'dispositionId'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'right'
    },
    {
      title: 'Unit Cost',
      dataIndex: 'unitCost',
      key: 'unitCost',
      align: 'right',
      render: (unitCost: number) => Number(unitCost).toFixed(2)
    },
    {
      title: 'Actions',
      align: 'center',
      render: (_, record, idx) => (
        <Space>
          <EditRecordModal idx={idx} record={record} />
          <LottieButton
            style={{ borderRadius: '50%', background: '#FFD4D4', borderColor: 'red' }}
            animationData={deleteLottie}
            lottieStyle={{ width: 20, height: 20, display: 'inline-block', transform: 'scale(2.3)' }}
            onClick={(): void => handleDelete(record)}
          />
        </Space>
      )
    }
  ];

  return <Table rowKey={(line): string => `${line.productNumber}-${line.ownerId}-${line.conditionId}-${line.dispositionId}`} dataSource={lineItems} columns={columns} />;
};
