import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReleasedProductsParams } from 'models/ReleasedProduct';

const initialState: ReleasedProductsParams = {
  offset: 0,
  productNumber: undefined,
  dataAreaId: 'mdsi'
};

export const donaldGrumpReleasedProductsParams = createSlice({
  name: 'donaldGrumpReleasedProductsParams',
  initialState,
  reducers: {
    setOffset: (state, { payload }: PayloadAction<ReleasedProductsParams['offset']>) => {
      state.offset = payload;
    },
    incrementOffset: (state) => {
      state.offset += 25;
    },
    setProductNumber: (state, { payload }: PayloadAction<ReleasedProductsParams['productNumber']>) => {
      state.productNumber = payload;
      state.offset = 0;
    }
  }
});

export const { setProductNumber, incrementOffset, setOffset } = donaldGrumpReleasedProductsParams.actions;
