import { Button } from 'antd';
import { useFormikContext } from 'formik';
import { POImportPayload } from 'pages/Home';
import { FC } from 'react';

export const PostButton: FC = () => {
  const { values, submitForm, resetForm } = useFormikContext<POImportPayload>();

  const handleSubmit = async (): Promise<void> => {
    await submitForm();
  };

  const formatStyle = values.lineItems.length ? { background: 'rgb(78, 147, 122)', color: 'white' } : undefined;

  return (
    <Button onClick={handleSubmit} disabled={!values.lineItems.length} style={formatStyle}>
      Post
    </Button>
  );
};
