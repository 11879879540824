import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { itemKey } from 'components/atoms/AddLineModal';
import { Division } from 'models/Division';
import { importPayloadSchema } from 'models/POImport';
import { ProductCatalogItem } from 'models/ProductCatalog';
import { ReleasedProduct } from 'models/ReleasedProduct';
import { LineItem, POImportPayload } from 'pages/Home';
import { setImportLoadingMessage, setIsImporting, setIsValidating } from 'redux/slices/importSlice';
import { ReduxState } from 'redux/store';
import { encodeValue } from 'utils/encodeValue';
import { GetOwnersResponseModel } from '../animal/animalsApi';

/* ******************** Base Query ******************** */
const baseUrl = process.env.REACT_APP_HECTOR_THE_SPIDER_BASE_URL;
const functionsKey = process.env.REACT_APP_API_HOST_KEY_HECTOR_THE_SPIDER;

export type LineItemErrorObject = LineItem & {
  errorMessage: string;
};
export interface ApiMultipleResponse<DataType> {
  totalCount: number;
  data: DataType[];
}

type PostedPOHeaderResponse = {
  dataAreaId: string;
  orderVendorAccountNumber: string;
  purchaseOrderNumber: string;
  requestedDeliveryDate: string;
  vendorOrderReference: string;
};

export type QueryErrorModel = FetchBaseQueryError & { data: { errorMessage: string } };

export const hectorTheSpiderBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as ReduxState).app.accessToken;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
        headers.set('x-functions-key', functionsKey);
        headers.set('Content-Type', 'application/json');
      }

      return headers;
    }
  })(args, api, extraOptions);
};

export const hectorTheSpiderApi = createApi({
  reducerPath: 'hectorTheSpiderApi',
  baseQuery: hectorTheSpiderBaseQuery,
  tagTypes: ['Bases', 'Base'],
  endpoints: (builder) => ({
    validateLineItems: builder.mutation<{ data: string; lineErrors: LineItemErrorObject[]; linesValidated: LineItem[] }, { importLines: LineItem[]; existingLines: LineItem[]; isAdmin: boolean }>({
      invalidatesTags: ['Bases'],
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        const token = (queryApi.getState() as ReduxState).app.accessToken;
        const acuityContext = (queryApi.getState() as ReduxState).app.acuityContext;
        const businessId = acuityContext?.selectedCustomer.business.id;
        const divisionId = acuityContext?.selectedCustomer.id;
        const dataAreaId = acuityContext?.selectedCustomer.business.dataAreaId;

        const conditionList = ['NEW', 'CERTRECOND', 'USED', 'REFURB'];
        const dispositionList = ['DEPLOY', 'SPARE'];
        const { importLines, existingLines, isAdmin } = arg;
        const linesValidated: LineItem[] = [...importLines];

        queryApi.dispatch(setIsValidating(true));

        const lineErrors: LineItemErrorObject[] = [];

        for await (const [idx, payloadLine] of importLines.entries()) {
          queryApi.dispatch(setImportLoadingMessage(`Validating Line Item ${idx + 1} of ${importLines.length}`));

          // Validate the payload through the yup scehma to check required fields and types

          try {
            await importPayloadSchema.validate(payloadLine);
          } catch (err) {
            console.log(err);
            lineErrors.push({ ...payloadLine, errorMessage: (err as { message: string }).message });
            continue;
          }

          // Check for duplicate line items

          if (existingLines.some((item) => itemKey(item) === itemKey(payloadLine))) {
            lineErrors.push({ ...payloadLine, errorMessage: 'Line item already exists' });
            continue;
          }

          // Validate that product exists in db

          if (isAdmin) {
            const productResponse = await fetch(
              `${process.env.REACT_APP_DONALD_GRUMP_BASE_URL}/${dataAreaId}/releasedProducts/${encodeValue({ isUrl: false, value: payloadLine.productNumber as string })}`,
              {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'x-functions-key': `${process.env.REACT_APP_API_HOST_KEY_DONALD_GRUMP}`,
                  authorization: `Bearer ${token}`,
                  'Access-Control-Allow-Origin': '*'
                }
              }
            );

            if (productResponse.status === 404) {
              queryApi.dispatch(setIsImporting(false));

              lineErrors.push({ ...payloadLine, errorMessage: 'Product does not exist.' });
              continue;
            }

            if (!productResponse.ok) {
              queryApi.dispatch(setIsImporting(false));

              lineErrors.push({ ...payloadLine, errorMessage: 'An error occured fetching product information. The team has been notified' });
              continue;
            }
            const productData: ReleasedProduct = await productResponse.json();

            const ownersResponse = await fetch(`${process.env.REACT_APP_ANIMAL_BASE_URL}/owners?overrideSkipTake=true`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'x-functions-key': `${process.env.REACT_APP_API_HOST_KEY_ANIMAL}`,
                authorization: `Bearer ${token}`,
                'Access-Control-Allow-Origin': '*'
              }
            });

            if (!ownersResponse.ok) {
              lineErrors.push({ ...payloadLine, errorMessage: 'An error occured fetching owners. The team has been notified' });
              continue;
            }

            const ownersData: GetOwnersResponseModel = await ownersResponse.json();

            if (!ownersData.data.some((owner) => owner.name.toUpperCase() === payloadLine.ownerId.toUpperCase())) {
              lineErrors.push({ ...payloadLine, errorMessage: 'Invalid owner.' });
              continue;
            }

            if (!conditionList.some((condition) => condition.toUpperCase() === payloadLine.conditionId.toUpperCase())) {
              lineErrors.push({ ...payloadLine, errorMessage: 'Invalid condition.' });
              continue;
            }
            if (!dispositionList.some((disposition) => disposition.toUpperCase() === payloadLine.dispositionId.toUpperCase())) {
              lineErrors.push({ ...payloadLine, errorMessage: 'Invalid disposition.' });
              continue;
            }

            const updatedPayload: LineItem = {
              ...payloadLine,
              description: payloadLine.description ?? productData?.productDescription ?? ''
            };

            linesValidated[idx] = updatedPayload;
          } else {
            const productResponse = await fetch(`${process.env.REACT_APP_JULIA_BASE_URL}/customers/${businessId}/productCatalogItems?productNumberContains=${payloadLine.productNumber}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'x-functions-key': `${process.env.REACT_APP_API_HOST_KEY_JULIA}`,
                authorization: `Bearer ${token}`,
                'Access-Control-Allow-Origin': '*'
              }
            });

            if (!productResponse.ok) {
              lineErrors.push({ ...payloadLine, errorMessage: 'An error occured fetching product information. The team has been notified' });
              continue;
            }

            const productData: ApiMultipleResponse<ProductCatalogItem> = await productResponse.json();

            if (productData.totalCount === 0) {
              lineErrors.push({ ...payloadLine, errorMessage: 'Product does not exist.' });
              continue;
            }

            // Get assigned owners from customer admin to check against but return an error if the api call fails

            const customerResponse = await fetch(`${process.env.REACT_APP_ABBY_CADABBY_BASE_URL}/divisions/${divisionId}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'x-functions-key': `${process.env.REACT_APP_API_HOST_KEY_ABBY_CADABBY}`,
                authorization: `Bearer ${token}`,
                'Access-Control-Allow-Origin': '*'
              }
            });

            if (!customerResponse.ok) {
              lineErrors.push({ ...payloadLine, errorMessage: 'An error occured fetching customer information. The team has been notified' });
              continue;
            }

            const customerData: Division = await customerResponse.json();

            // Validate owner, condition and disposition

            if (!customerData.assignedOwners.some((owner) => owner.toUpperCase() === payloadLine.ownerId.toUpperCase())) {
              lineErrors.push({ ...payloadLine, errorMessage: 'Invalid owner.' });
              continue;
            }

            if (!conditionList.some((condition) => condition.toUpperCase() === payloadLine.conditionId.toUpperCase())) {
              lineErrors.push({ ...payloadLine, errorMessage: 'Invalid condition.' });
              continue;
            }
            if (!dispositionList.some((disposition) => disposition.toUpperCase() === payloadLine.dispositionId.toUpperCase())) {
              lineErrors.push({ ...payloadLine, errorMessage: 'Invalid disposition.' });
              continue;
            }

            // Find the product and update the payload with the product description

            const foundProoduct = productData?.data.find((product) => product.productNumber.trim().toLowerCase() === payloadLine.productNumber.trim().toLowerCase());

            const updatedPayload: LineItem = {
              ...payloadLine,
              description: payloadLine.description ?? foundProoduct?.productDescription ?? ''
            };

            linesValidated[idx] = updatedPayload;
          }
        }

        queryApi.dispatch(setIsValidating(false));

        // Filter out the lines that have errors and return the validated lines and the errors

        const filteredLines = linesValidated.filter((lineItem) => !lineErrors.some((errorItem) => errorItem.productNumber === lineItem.productNumber));

        return { data: { data: 'Successfully validated line items.', lineErrors, linesValidated: filteredLines } };
      }
    }),
    postLineItems: builder.mutation<{ data: string; lineErrors: LineItemErrorObject[]; errorMessage?: string }, { payload: POImportPayload; isAdmin: boolean }>({
      invalidatesTags: ['Bases'],
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        const token = (queryApi.getState() as ReduxState).app.accessToken;
        const acuityContext = (queryApi.getState() as ReduxState).app.acuityContext;
        const dataAreaId = acuityContext?.selectedCustomer.business.dataAreaId;
        const { lineItems } = arg.payload;
        const { isAdmin } = arg;
        const lineErrors: LineItemErrorObject[] = [];

        queryApi.dispatch(setImportLoadingMessage(`Posting header information`));
        queryApi.dispatch(setIsImporting(true));

        let payload: POImportPayload = arg.payload;

        if (!isAdmin) {
          if (!acuityContext?.selectedCustomer.id) return { data: { data: 'Error', lineErrors: [], errorMessage: 'No division selected' } };
          payload = { ...arg.payload, divisionId: acuityContext.selectedCustomer.id };
        }

        const headerResponse = await baseQuery({ url: 'purchaseOrders', method: 'POST', body: payload });

        if (headerResponse.error) {
          const error = headerResponse.error as QueryErrorModel;

          console.log(error.data, 'error');

          queryApi.dispatch(setIsImporting(false));

          return { data: { data: 'Error', lineErrors: [], errorMessage: error.data.errorMessage } };
        }

        const postedHeader: PostedPOHeaderResponse = headerResponse.data as PostedPOHeaderResponse;

        for await (const [idx, payloadLine] of lineItems.entries()) {
          queryApi.dispatch(setImportLoadingMessage(`Posting Line Item ${idx + 1} of ${lineItems.length}`));

          let payload: LineItem = payloadLine;

          if (!isAdmin) {
            payload = { ...payloadLine, divisionId: acuityContext?.selectedCustomer.id };
          }

          const productResponse = await fetch(
            `${process.env.REACT_APP_DONALD_GRUMP_BASE_URL}/${dataAreaId}/releasedProducts/${encodeValue({ isUrl: false, value: payloadLine.productNumber as string })}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'x-functions-key': `${process.env.REACT_APP_API_HOST_KEY_DONALD_GRUMP}`,
                authorization: `Bearer ${token}`,
                'Access-Control-Allow-Origin': '*'
              }
            }
          );

          if (!productResponse.ok) {
            queryApi.dispatch(setIsImporting(false));

            lineErrors.push({ ...payloadLine, errorMessage: 'An error occured fetching product information. The team has been notified' });
            continue;
          }
          const productData: ReleasedProduct = await productResponse.json();

          const ocd = `${payloadLine.ownerId}-${payloadLine.conditionId}-${payloadLine.dispositionId}`.toLowerCase();
          const formattedVariants = productData.releasedProductVariants.map((variant) => `${variant.ownerId}-${variant.conditionId}-${variant.dispositionId}`.toLowerCase());

          if (!formattedVariants.includes(ocd)) {
            const createReleasedProductVariant = await fetch(
              `${process.env.REACT_APP_DONALD_GRUMP_BASE_URL}/${dataAreaId}/releasedProducts/${encodeValue({
                isUrl: false,
                value: payloadLine.productNumber as string
              })}/releasedProductVariants?createForProductMasterIfNotExist=true`,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'x-functions-key': `${process.env.REACT_APP_API_HOST_KEY_DONALD_GRUMP}`,
                  authorization: `Bearer ${token}`,
                  'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify({
                  conditionId: payloadLine.conditionId,
                  dispositionId: payloadLine.dispositionId,
                  ownerId: payloadLine.ownerId
                })
              }
            );

            if (!createReleasedProductVariant.ok) {
              queryApi.dispatch(setIsImporting(false));

              console.log(createReleasedProductVariant, 'createReleasedProductVariant');

              lineErrors.push({ ...payloadLine, errorMessage: 'Line item was submitted but product information could not be fetched.' });
              continue;
            }
          }
          const submitLineItem = await baseQuery({ url: `purchaseOrders/${postedHeader.purchaseOrderNumber}/lines`, method: 'POST', body: payload });

          if (submitLineItem.error) {
            const error = submitLineItem.error as QueryErrorModel;

            lineErrors.push({ ...payloadLine, errorMessage: error.data.errorMessage });
            continue;
          }
        }

        queryApi.dispatch(setIsImporting(false));

        return { data: { data: `Successfully created purchase order: ${postedHeader.purchaseOrderNumber}.`, lineErrors } };
      }
    })
  })
});

export const { useValidateLineItemsMutation, usePostLineItemsMutation } = hectorTheSpiderApi;
