import { InputNumber } from 'antd';
import { valueType } from 'antd/es/statistic/utils';
import { InputNumberProps } from 'antd/lib';
import { Field, FieldProps, useField } from 'formik';
import { FC } from 'react';
import { FormLabel } from './FormLabel';

interface Props extends InputNumberProps {
  fieldName: string;
  placeholder?: string;
  label: string;
}

export const CurrencyInput: FC<Props> = ({ fieldName, placeholder, label, ...rest }) => {
  const [{ value: quantity }] = useField('quantity');
  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.code.includes('Key')) {
      event.preventDefault();
    }
  };

  const handleFormat = (val: valueType | null): valueType | null => {
    if (typeof val === 'number' && val?.toString()?.split('.')[1]?.length > 2) {
      return val.toFixed(2);
    }

    return val;
  };

  return (
    <Field name={fieldName} placeholder={placeholder}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { setFieldTouched, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }: FieldProps): JSX.Element => {
        return (
          <div>
            <FormLabel label={label} />
            <InputNumber
              addonBefore="$"
              onKeyDown={handleEnter}
              step={0.01}
              value={field.value}
              onChange={(val): void => {
                setFieldValue(fieldName, handleFormat(val));
              }}
              onFocus={() => setFieldTouched(fieldName, false)}
              style={{ borderColor: meta.error && meta.touched ? '#dc3545' : undefined, width: '100%' }}
              controls={false}
              {...rest}
            />
            <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
          </div>
        );
      }}
    </Field>
  );
};
