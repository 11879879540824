import { Card, Space, Spin, Typography } from 'antd';
import { AddLineModal } from 'components/atoms/AddLineModal';
import { ImportButton } from 'components/atoms/ImportButton';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { ImportTable } from 'components/molecules/ImportTable';
import { FC } from 'react';
import { useAppSelector } from 'redux/store';

export const ImportTableCard: FC = () => {
  const { isValidating, importLoadingMessage } = useAppSelector((state) => state.import);

  return (
    <Spin spinning={isValidating} indicator={<LoaderWithMessage loadingMessage={importLoadingMessage} />}>
      <Card
        headStyle={{ backgroundColor: 'rgb(206, 213, 242)' }}
        title={
          <Typography.Title level={4} style={{ fontWeight: 'normal', padding: 0, margin: 0 }}>
            Import Information
          </Typography.Title>
        }
        extra={
          <Space>
            <ImportButton />
            <AddLineModal />
          </Space>
        }
        bodyStyle={{ minHeight: '100%' }}
        style={{ height: '100%', minHeight: '100%', width: '100%' }}>
        <ImportTable />
      </Card>
    </Spin>
  );
};
