import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Card, Modal, Typography, message } from 'antd';
import { LineItemForm } from 'components/molecules/LineItemForm';
import { FormikProvider, useFormik, useFormikContext } from 'formik';
import { importPayloadSchema } from 'models/POImport';
import { LineItem, POImportPayload } from 'pages/Home';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setOffset, setProductNumber } from 'redux/services/donaldGrump/releasedProductsParams';

export const itemKey = (record: LineItem): string => `${record.productNumber}-${record.ownerId}-${record.conditionId}-${record.dispositionId}`;

export const AddLineModal: FC = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const {
    setFieldValue,
    values: { lineItems }
  } = useFormikContext<POImportPayload>();

  const formik = useFormik<LineItem>({
    enableReinitialize: true,
    validationSchema: importPayloadSchema,
    initialValues: {
      lineNumber: lineItems.length + 1,
      productNumber: '',
      ownerId: '',
      conditionId: '',
      dispositionId: '',
      description: '',
      quantity: 1,
      unitCost: 0.0
    },
    onSubmit: async (values) => {
      console.log(values);
      if (lineItems.some((item) => itemKey(item) === itemKey(values))) {
        return message.error('Cannot add duplicate line item');
      }
      setFieldValue('lineItems', [...lineItems, values]);
      setIsOpen(false);
      formik.resetForm();
    }
  });

  const handleSubmit = async (): Promise<void> => {
    await formik.submitForm();
  };

  const handleCancel = (): void => {
    setIsOpen(false);
    dispatch(setOffset(0));
    dispatch(setProductNumber(undefined));
    formik.resetForm();
  };

  return (
    <FormikProvider value={formik}>
      <Button onClick={(): void => setIsOpen(true)} type="primary" icon={<PlusCircleOutlined />}>
        Add Line Item
      </Button>
      <Modal destroyOnClose okText="Submit" onOk={handleSubmit} closable={false} open={isOpen} onCancel={handleCancel}>
        <Card
          headStyle={{ backgroundColor: 'rgba(24, 167, 153, 0.518)' }}
          title={
            <Typography.Title level={4} style={{ fontWeight: 'normal', padding: 0, margin: 0 }}>
              Add Line Item
            </Typography.Title>
          }
          bodyStyle={{ minHeight: '100%' }}
          style={{ height: '100%', minHeight: '100%', width: '100%' }}>
          <LineItemForm />
        </Card>
      </Modal>
    </FormikProvider>
  );
};
