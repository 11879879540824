import { Spin } from 'antd';
import { useAuthentication } from 'hooks/useAuthentication';
import { useGetDivisionQuery } from 'redux/services/abbyCadabby/abbyCadabbyApi';
import { useGetOwnersQuery } from 'redux/services/animal/animalsApi';
import { useAppSelector } from 'redux/store';
import { FieldInputSelect } from './FieldInputSelect';

export const OwnersSelect = (): JSX.Element => {
  const { isAdmin } = useAuthentication();
  const { acuityContext } = useAppSelector((state) => state.app);
  const id = acuityContext?.selectedCustomer.id;
  const { data: divisionData, isLoading: isCustomerLoading } = useGetDivisionQuery(id as string, { skip: !id || isAdmin });
  const { data: ownerData, isLoading: isOwnerLoading } = useGetOwnersQuery({ overrideSkipTake: true }, { skip: !isAdmin });
  const owners = divisionData?.assignedOwners?.map((owner) => ({ label: owner, value: owner })) ?? [];

  const ownersOptions = (): { label: string; value: string }[] | undefined => {
    if (ownerData?.data) {
      return ownerData.data.map((owner) => ({ label: owner.name, value: owner.name }));
    }

    return owners;
  };

  return (
    <Spin spinning={isCustomerLoading || isOwnerLoading}>
      <FieldInputSelect fieldName="ownerId" label="Owner" options={ownersOptions()} />
    </Spin>
  );
};
