import { DownloadOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Tooltip, Typography } from 'antd';
import Upload, { RcFile } from 'antd/es/upload';
import { useFormikContext } from 'formik';
import { useAuthentication } from 'hooks/useAuthentication';
import { LineItem, POImportPayload } from 'pages/Home';
import { LineItemErrorObject, useValidateLineItemsMutation } from 'redux/services/hectorTheSpider/hectorTheSpiderApi';
import { useAppSelector } from 'redux/store';
import { read, utils, writeFile } from 'xlsx';

export const ImportButton: React.FC = () => {
  const [validateLineItems] = useValidateLineItemsMutation();
  const { isAdmin } = useAuthentication();
  const { isImporting } = useAppSelector((state) => state.import);
  const {
    setFieldValue,
    values: { lineItems }
  } = useFormikContext<POImportPayload>();

  const handleErrorModal = (importErrors: LineItemErrorObject[]): void => {
    Modal.confirm({
      title: 'Import Errors',
      bodyStyle: { maxHeight: 800 },
      cancelText: 'Export',
      onCancel: () => {
        const formattedErrors = importErrors.map(({ productNumber, ownerId, conditionId, dispositionId, quantity, unitCost, errorMessage }) => {
          return {
            ['Product Number']: productNumber,
            ['Owner']: ownerId,
            ['Condition']: conditionId,
            ['Disposition']: dispositionId,
            ['Quantity']: quantity,
            ['Unit Cost']: unitCost,
            ['Error Message']: errorMessage
          };
        });

        const worksheeet = utils.json_to_sheet(formattedErrors);
        const workbook = utils.book_new();

        utils.book_append_sheet(workbook, worksheeet, 'assets');
        writeFile(workbook, 'import-errors.xlsx');
      },
      width: 1000,
      icon: <></>,
      content: (
        <div style={{ border: '1px solid rgba(0,0,0,.1)', borderRadius: 10, padding: 10, marginTop: 20, overflow: 'scroll', height: 600 }}>
          <Row style={{ marginTop: 20, marginLeft: 5, marginBottom: 5 }}>
            <Col span={5}>
              <Typography.Text style={{ fontSize: 15 }}>Item Id</Typography.Text>
            </Col>
            <Col span={19}>
              <Typography.Text style={{ fontSize: 15 }}>Message</Typography.Text>
            </Col>
          </Row>
          {importErrors.map((error, i) => (
            <Row gutter={[10, 10]} key={error.errorMessage + i} style={{ padding: 10, background: 'rgba(0,0,0,.1)', borderRadius: 10, marginBottom: 10 }}>
              <Col span={5}>{error.productNumber}</Col>
              <Col span={19}>{error.errorMessage}</Col>
            </Row>
          ))}
        </div>
      )
    });
  };

  const handleImport = async (importData: LineItem[]): Promise<void> => {
    const res = await validateLineItems({ importLines: importData, existingLines: lineItems, isAdmin }).unwrap();

    const linesToAdd = res.linesValidated.map((line, i) => ({ ...line, lineNumber: lineItems.length + i + 1 }));

    setFieldValue('lineItems', [...lineItems, ...linesToAdd]);

    !!res.lineErrors.length && handleErrorModal(res.lineErrors);
  };

  const parseData = async (file: RcFile): Promise<void> => {
    const reader = new FileReader();

    reader.onload = (e): void => {
      if (e.target && e.target.result !== null) {
        const data = e.target.result;
        const workbook = read(data, { type: 'array' });

        const sheetNameAssetHeader = workbook.SheetNames[0];

        const worksheetAssetHeader = workbook.Sheets[sheetNameAssetHeader];

        const jsonAssetHeader = utils.sheet_to_json(worksheetAssetHeader, { raw: false });

        const payload: LineItem[] = jsonAssetHeader.map((item: any) => ({
          lineNumber: 0,
          productNumber: item['Product Number'],
          ownerId: item['Owner'],
          conditionId: item['Condition'],
          dispositionId: item['Disposition'],
          quantity: item['Quantity'] ?? 0,
          unitCost: item['Unit Cost'] ?? 0
        }));

        handleImport(payload);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <Button.Group>
      <Tooltip title="Download Import Template">
        <Button download href="../../POImportTemplate.xlsx" icon={<DownloadOutlined />} />
      </Tooltip>
      <Button disabled={isImporting} loading={isImporting}>
        <Upload beforeUpload={parseData} showUploadList={false}>
          Import
        </Upload>
      </Button>
    </Button.Group>
  );
};
